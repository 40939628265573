@media screen and (min-width: 70em) {
  .proj-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 3em;
    width: 100%;
    margin: 20px;
    padding: 20px;
  }
}

@media screen and (max-wudth: 70em) {
  .proj-container {
    display: grid;
    justify-content: center;
    width: 100%;
    margin: 20px;
    padding: 20px;
  }
}