@media screen and (min-width: 70em) {
  .scale-in-center {
    width: 33%;
    border: solid 5px;
    border-radius: 8px;
    padding: 20px;
    background-color: rgb(255, 255, 255);
  }
}

@media screen and (max-width: 70em) {
  .scale-in-center {
    width: 50%;
    border: solid 5px;
    border-radius: 8px;
    padding: 20px;
    background-color: rgb(255, 255, 255);
  }
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.header {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 40px;
}



.scale-in-center {
	-webkit-animation: scale-in-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s both;
	        animation: scale-in-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-22 19:7:11
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

.links {
  display: flex;
  width: 35%;
  justify-content: space-around;
}

.logo {
  width: 100%; 
}

.logo-link {  
  width: 20%;
}

.intro-paragraph {
  text-align: center;
}

p {
  line-height: 1.4em;
}

.intro-container {
  margin-top: 70px;
}

.profile-img {
  margin-top: 40px;
}